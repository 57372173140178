.kubic .splash {
  color: white;
}

.kubic .splash button {
  background: #84A06D;
  color: white;
}

.kubic #menu-page {
  background-image: url(/public/assets/images/kubic.png);
}

.kubic .menu {
  background-color: rgba(255, 255, 255, 0);
  color: white;
  padding-top: 2rem;
}

.kubic .list {
  background-color: rgba(255, 255, 255, 0);
  color: white;
  padding-top: 2rem;
}

.kubic .react-tabs__tab-panel,
react-tabs__tab-panel--selected {
  color: black;
}

.kubic .tab {
  background: #ffffff;
  border-color: #ffffff;
  color: black;
}

.kubic .tab-active {
  background: #84A06D;
  border-color: #84A06D;
  color: white;
}

.kubic .share {
  background: #84A06D;
  border-color: #84A06D;
  color: white;
}

.kubic .preferred-default-screen {
  background-color: rgba(255, 255, 255, 0);
  color: white;
}

.kubic .drinks {
  background-color: rgba(255, 255, 255, 0.5);
}

.kubic .drinks.even {
  background-color: rgba(255, 255, 255, 0);
}

.kubic .drinks.odd {
  background-color: rgba(0, 0, 0, 0.5);
}

.barriobotanico .dishes.even {
  background-color: rgba(255, 255, 255, 0);
}

.barriobotanico .dishes.odd {
  background-color: rgba(255, 255, 255, 0.5);
}

.barriobotanico .dish>div {
  background-color: rgba(255, 255, 255, 0.8);
}

.kubic .trigger.even {
  color: white;
  background-color: rgba(255, 255, 255, 0);
}

.kubic .trigger.odd {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
}

.barriobotanico .drink>div {
  background-color: rgba(255, 255, 255, 0.8);
}

.kubic .arrow-trigger {
  background: black;
}

.kubic .powered-by {
  color: white;
}

.kubic .drink-modal-trigger {
  color: black;
}

.kubic .allergens-modal-trigger span {
  background-color: #84A06D;
}
