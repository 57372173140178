/* ---Santo--- */
/* Splashpage */
.santo .splash button {
    background-color: #AA9468;
}

.santo .splash {
    color: white
}
/*  */

/* Menupage  fork->menu */
.santo .tab {
    background: black;
    border-color: black;
    border-color: #AA9468;
    color: white;
}

.santo .tab-active {
    background: #AA9468;
    border-color: #AA9468;
    color: black;
    background-color: #AA9468;
}

.santo .arrow-trigger {
    background: black;
    color: white;
    box-shadow: none;
}

.santo .cta {
    background: #AA9468;
    color: black;
}

.santo .filter-modal-trigger {
    background: #AA9468;
    color: black;
}

.santo .subscription {
    background: #AA9468;
    color: black;
}

.santo .share {
    background: #AA9468;
    color: black;
}

.santo .add-cart-item {
    background: #AA9468;
    color: black;
}

.santo .allergens-modal-trigger span {
    background: #AA9468;
    color: black;
}

.santo .cta-no-orders {
    background: #AA9468;
    color: black;
}

.santo .menu {
    color: white;
    background: rgba(255, 255, 255, 0);
    margin-top: 0;
}

.santo .even {
    color: white;
    background: rgba(255, 255, 255, 0);
}

.santo .odd {
    color: white;
    background: rgba(255, 255, 255, 0);
}

.santo .powered-by {
    color: white;
}

.santo .dish>div {
    color: black;
    background: rgba(255, 255, 255, 0.9);
}

.santo .drink>div {
    color: black;
    background: rgba(255, 255, 255, 0.9);
}

.santo .drink-modal-trigger {
    color: black;
}

.santo .ingredients span {
    color: black;
}

/*  */
