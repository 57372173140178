.redbull-modal-content .modal-wrapper {
  @apply m-auto w-[calc(100%-1em)] h-[90%] rounded-2xl;
}

.redbull-modal-content img {
  @apply m-0 mx-auto p-0;
}

@media screen and (max-width: 375px) {
  .redbull-modal-content .modal-wrapper {
    @apply m-auto w-[calc(100%-2rem)] h-[95%] rounded-2xl;
  }

  .redbull-modal-content img {
    @apply mt-0 w-[90%] h-[85%];
  }
}

@media screen and (min-width: 440px) {
  .redbull-modal-content img {
    @apply mt-0 w-[85%] h-[85%];
  }

  .redbull-modal-content .discover-more a {
    @apply w-[85%];
  }
}
