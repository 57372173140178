:root {
  --primary: #001d37; /*blu*/
  --secondary: #a6976a; /*oro*/
  --tertiary: #004484;
}

.krudo .share {
  color: white;
  background: var(--tertiary);
}

.krudo .subscription {
  color: white;
  background: var(--primary);
}

.krudo .splash h1 {
  color: white;
}

.krudo .splash button {
  background: var(--secondary);
  color: white;
}

.ilovepoke .restaurant-image {
  background: white;
}

.belliner img.restaurant-poster {
  height: 250px;
  object-fit: cover;
}

.krudo .menu {
  color: white;
  z-index: 1;
  background-color: var(--primary);
  margin-top: 4px;
}

.krudo .collapsible-trigger {
  background: var(--primary);
  color: var(--secondary);
}

.krudo .drink div,
.krudo .product,
.krudo .dish div {
  background: white;
  color: var(--primary);
  @apply mb-1 rounded-lg;
}

.krudo .dishes,
.krudo .drinks {
  background: var(--primary);
  @apply mx-4 rounded-b-lg;
}

.krudo .tab {
  background: white;
  border: solid var(--primary);
  color: black;
}

.krudo .tab-active {
  background: var(--secondary);
  color: white;
}

.krudo .arrow-trigger {
  background-color: var(--tertiary);
  border: none;
  @apply shadow-none;
}

.krudo .dish-modal-trigger,
.krudo .drink-modal-trigger {
  color: var(--secondary);
}

.krudo .allergens-modal-trigger span {
  background: var(--secondary);
}

.krudo .powered-by {
  color: white;
}
