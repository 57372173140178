.vanvitelli .splash button {
    background: #BA923B;
}

.vanvitelli .tab {
    border-color: #E2C79A;
}

.vanvitelli .tab-active {
    background: #E2C79A;
    border-color: #E2C79A;
    color: black;
}

.vanvitelli .share {
    background: #706C5F;
    border-color: #706C5F;
    color: white;
}

.vanvitelli .filter-modal-trigger {
    background: #BA923B;
    color: white;
}

.vanvitelli .arrow-trigger {
    background: #706C5F;
}

.vanvitelli .dish-modal-trigger {
    color: #03012C;
}

.vanvitelli .allergens-modal-trigger span {
    background-color: #BA923B;
}
