/* ---Redbull--- */
/* Splashpage */
.redbull .splash {
    color: white;
    font-weight: 700;
}

.redbull .splash button {
    color: black;
    font-weight: 700;
    background: #E8E8E8;
    border-radius: 30px;
    padding: 1rem;
}

.redbull .splash button:nth-child(3) {
    color: white;
    font-weight: 700;
    background: #DC0313;
}

/*  */

.redbull header h1 {
    font-weight: 700;
}

/* Menupage  fork->list */
.redbull .tab {
    background: #20447A;
    border-color: #E8E8E8;
    color: white;
}

.redbull .tab-active {
    background: #E8E8E8;
    border-color: #E8E8E8;
    color: black;
}

.redbull .share {
    background-color: #BE2840;
}

.redbull .arrow-trigger {
    background: #BE2840;
    box-shadow: none;
}


.redbull .list {
    background: #2B599F;
    color: white;
}

.redbull .Collapsible:nth-child(odd) div {
    background: #2B599F;
    color: white;
}

.redbull .Collapsible:nth-child(even) div {
    background: #20447A;
    color: white;
}

.redbull .Collapsible .Collapsible__contentInner div.product div {
    background-color: white;
    color: black;
}

.redbull .Collapsible .Collapsible__contentInner div.product div div {
    border-radius: 10px;
}

.redbull .Collapsible .Collapsible__contentInner div.drink div {
    background-color: white;
    color: black;
    border-radius: 10px;
}

.redbull .Collapsible .Collapsible__contentInner div div div.cta {
    background-color: #FEC801;
    color: black;
    border-radius: 0 0 9px 9px;
}

.redbull .drink-modal-trigger {
    color: #BE2840;
}

.redbull .cta {
    background: #FEC801;
    font-weight: 700;
}

.redbull .bucket {
    background: #FEC801;
    color: black;
    font-weight: 700;
}

.redbull .filter-modal-trigger {
    background: #E8E8E8;
    font-weight: 700;
    color: black;
    padding: 1rem;
}

.redbull .filter-modal-content div {
    color: black;
}

.redbull .filter-modal-content p {
    color: white;
}

.redbull .filter-modal-content .button-native {
    color: black;
}

.redbull .fixed-cart {
    background: #FEC801;
    color: black;
}

.redbull .add-cart-item {
    background: #FEC801;
    font-weight: 700;
}

.redbull ion-modal {
    --background: #20447A;
    /* -webkit-text-fill-color: white; */
}

.redbull ion-modal.custom-cta {
    --background: white;
    -webkit-text-fill-color: black;
}

.redbull .product .dish-modal-trigger {
    color: #BE2840;
}

.redbull .allergens-modal-trigger span {
    background: #E8E8E8;
    padding: 1rem;
    color: black;
}

.redbull .powered-by {
    color: #e7e8e7;
}

.redbull .delivery-popup-modal-content {
    color: white;
}

.redbull .dish-modal-content div:nth-child(2) {
    color: white;
}

.redbull #orders-page h2 {
    background-color: #20447A;
    color: white;
}

.redbull #orders-page .cart {
    color: black;
}

.redbull #orders-page>div>div>p {
    color: white;
}

.redbull #orders-page>div>div>button:last-child {
    background: #FEC801;
}

.redbull ion-content {
    --background: #20447A !important;
}

.redbull #tables-page h2 {
    background-color: #20447A;
    color: white;
}

.redbull #tables-page .cart {
    color: black;
}

.redbull #tables-page>div>div>p {
    color: white;
}

.redbull #tables-page>div>div>button:last-child {
    background: #FEC801;
}

.redbull button[type="submit"] {
    background: #FEC801;
    opacity: 1;
}

.redbull #tables-page>div.px-6>div.py-6.text-sm.text-center {
    color: white;
}

.redbull ion-content {
    --background: #20447A !important;
}

.redbull #tables-page>div.px-6>div.px-6.py-2.bg-white.rounded-lg {
    color: black;
}

.redbull #tables-page>div.w-full.flex.flex-col.justify-center.p-4 {
    color: white;
}

.redbull #tables-page>div.px-6>div.px-6.py-2.bg-white.rounded-lg>p {
    color: black;
}

.redbull #success-page {
    color: white;
    background: #20447A;
}

.redbull #success-page>div.fixed.bottom-0.w-full.flex.flex-col.justify-center.mt-8.px-4.py-6.bg-white {
    background-color: #20447A;
}

.redbull #success-page a {
    background: #FEC801;
    color: black;
}

.redbull .drink-modal-content p {
    color: white;
}

.redbull #payments-page {
    color: white;
}
