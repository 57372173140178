/* ---CAMPARINO--- */
/* Splashpage */
.camparino .splash {
  color: black;
}

.camparino .splash button {
  background: #db0525;
  color: white;
}
/*  */

/* Menupage  fork->list */
.camparino .tab {
  background: #ffffff;
  border-color: #1c365d;
  color: black;
}

.camparino .tab-active {
  background: #1c365d;
  border-color: #e7e8e7;
  color: #ffffff;
}

.camparino .arrow-trigger {
  background: #1c365d;
  box-shadow: none;
}

.camparino .cta {
  background: #db0525;
}

.camparino .cta button {
  color: white;
}

.camparino .filter-modal-trigger {
  background: #1c365d;
}

.camparino .subscription {
  background: #1c365d;
}

.camparino .share {
  background: #1c365d;
}

.camparino .bucket {
  background: #db0525;
  color: black;
}

.camparino .cta-tasting-menu {
  background: #1c365d;
  color: white;
}

.camparino .allergens-modal-trigger span {
  background: #1c365d;
  color: white;
}

/*  */
