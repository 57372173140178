/* ---RADETZKY--- */
/* Splashpage */

.radetzky .splash button {
  font-weight: bold;
  font-family: "Bodoni", Didot, "Didot LT STD", "Book Antiqua", Garamond,
    "Times New Roman", serif;
  background-color: #656565;
}

.radetzky .splash {
  font-weight: bold;
  font-family: "Bodoni", Didot, "Didot LT STD", "Book Antiqua", Garamond,
    "Times New Roman", serif;
}

/*  */

/* Menupage  fork->menu */
.radetzky .tab {
  background: white;
  border-color: black;
  color: black;
  font-weight: bold;
  font-family: "Bodoni", Didot, "Didot LT STD", "Book Antiqua", Garamond,
    "Times New Roman", serif;
}

.radetzky .tab-active {
  background: #3c3c3c;
  border-color: #3c3c3c;
  color: white;
}

.radetzky .arrow-trigger {
  background: #2b2b2b;
  color: white;
  box-shadow: none;
}

.radetzky .cta {
  background: #fec34d;
}

/* .radetzky .cta > button {
    color: white;
  } */

.radetzky .filter-modal-trigger {
  background: #656565;
  color: white;
  font-weight: bold;
  font-family: "Bodoni", Didot, "Didot LT STD", "Book Antiqua", Garamond,
    "Times New Roman", serif;
}

.radetzky .subscription {
  background: black;
  color: white;
}

.radetzky .share {
  background: black;
  color: white;
}

.radetzky .bucket {
  background: #656565;
  color: white;
  font-weight: bold;
  font-family: "Bodoni", Didot, "Didot LT STD", "Book Antiqua", Garamond,
    "Times New Roman", serif;
}

.radetzky .add-cart-item {
  background: #656565;
  color: white;
  font-weight: bold;
  font-family: "Bodoni", Didot, "Didot LT STD", "Book Antiqua", Garamond,
    "Times New Roman", serif;
}

.radetzky .allergens-modal-trigger span {
  background: #656565;
  color: white;
  font-weight: bold;
  font-family: "Bodoni", Didot, "Didot LT STD", "Book Antiqua", Garamond,
    "Times New Roman", serif;
}

.radetzky .cta-tasting-menu {
  background: #656565;
  color: white;
  font-weight: bold;
  font-family: "Bodoni", Didot, "Didot LT STD", "Book Antiqua", Garamond,
    "Times New Roman", serif;
}

.radetzky .cta-no-orders {
  background: #656565 !important;
}

.radetzky button {
  color: white;
  font-weight: bold;
  font-family: "Bodoni", Didot, "Didot LT STD", "Book Antiqua", Garamond,
    "Times New Roman", serif;
}

.radetzky .dish-modal-trigger {
  color: #000000;
}

.radetzky .product-content {
  background: white;
}

.radetzky .collapsible-trigger {
  background-color: rgb(246, 246, 246);
}

.radetzky .menu {
  background-color: rgb(246, 246, 246);
}

.radetzky .even {
  font-weight: bolder;
  font-family: "Bodoni", Didot, "Didot LT STD", "Book Antiqua", Garamond,
    "Times New Roman", serif;
}

.radetzky .odd {
  font-weight: bolder;
  font-family: "Bodoni", Didot, "Didot LT STD", "Book Antiqua", Garamond,
    "Times New Roman", serif;
  background: white;
}

.radetzky .ingredients {
  font-weight: normal;
}

.radetzky .allergen {
  font-weight: normal;
}

.radetzky .allergens-modal-content {
  font-weight: normal;
  font-family: "Bodoni", Didot, "Didot LT STD", "Book Antiqua", Garamond,
    "Times New Roman", serif;
}

.radetzky h1 {
  font-weight: bolder;
  font-family: "Bodoni", Didot, "Didot LT STD", "Book Antiqua", Garamond,
    "Times New Roman", serif;
}

.radetzky header {
  font-family: "Bodoni", Didot, "Didot LT STD", "Book Antiqua", Garamond,
    "Times New Roman", serif;
}

.radetzky .dish div {
  background: white;
  border-radius: 10px;
}

.radetzky .dish {
  background: rgb(246, 246, 246);
}

.radetzky .drink {
  background: rgb(246, 246, 246);
}

.radetzky .drink div {
  background: white;
  border-radius: 10px;
}

.radetzky .odd {
  background-color: rgb(246, 246, 246);
}

.radetzky .dish-modal-content {
  font-family: "Bodoni", Didot, "Didot LT STD", "Book Antiqua", Garamond,
    "Times New Roman", serif;
}

.radetzky .dish-modal-content button {
  color: black;
}

.radetzky .details {
  font-weight: normal;
  font-style: normal;
}

.radetzky .allergens-modal-content button {
  color: black;
}

.radetzky .preferred-default-screen {
  font-family: "Bodoni", Didot, "Didot LT STD", "Book Antiqua", Garamond,
    "Times New Roman", serif;
}

/*  */
