.fellini .splash {
    color: white;
}

.fellini .splash button {
    background: white;
    color: black;
}

.fellini #menu-page {
    background-image: url(/public/assets/images/fellini.png);
}

.fellini .menu {
    background-color: rgba(255, 255, 255, 0);
    color: white;
    padding-top: 2rem;
}

.fellini .list {
    background-color: rgba(255, 255, 255, 0);
    color: white;
    padding-top: 2rem;
}

.fellini .react-tabs__tab-panel,
react-tabs__tab-panel--selected {
    color: black;
}

.fellini .tab {
    background: #ffffff;
    border-color: #ffffff;
    color: black;
}

.fellini .tab-active {
    background: black;
    border-color: black;
    color: white;
}

.fellini .share {
    background: white;
    border-color: white;
    color: black;
}

.fellini .preferred-default-screen {
    background-color: rgba(255, 255, 255, 0);
    color: white;
}

.fellini .drinks {
    background-color: rgba(255, 255, 255, 0.5);
}

.fellini .drinks.even {
    background-color: rgba(255, 255, 255, 0.0);
}

.fellini .drinks.odd {
    background-color: rgba(255, 255, 255, 0.8);
}

.fellini .dishes.even {
    background-color: rgba(255, 255, 255, 0);
}

.fellini .dishes.odd {
    background-color: rgba(255, 255, 255, 0.8);
}

.fellini .dish>div {
    background-color: rgba(255, 255, 255, 0.9);
}

.fellini .trigger.even {
    color: white;
    background-color: rgba(255, 255, 255, 0);
}

.fellini .trigger.odd {
    background-color: rgba(255, 255, 255, 0.8);
}

.fellini .drink>div {
    background-color: rgba(255, 255, 255, 0.9);
}

.fellini .arrow-trigger {
    background: #c0c0c0;
}

.fellini .powered-by {
    color: white;
}

.fellini .allergens-modal-trigger span {
    background-color: white;
    color: black;
}

.fellini .callrestaurant-modal-trigger>button {
    background-color: white;
    color: black;
}
