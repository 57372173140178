/*.primo ion-content {
     --background: url('/public/assets/primo/bg.png') 0 100px/100% auto repeat;
     filter: brightness(40%);
 }*/

/* .primo ion-content {
     --background: #323232;
 } */

/* bg portata : D0597B
 bordo portara chiusa : E3A6B7
 verde acqua: 113 187 181
 */
:root {
  --primary: #f1577e; /*bordi*/
  --secondary: #ee597d; /*background*/
  --tertiary: #33bdb6; /*azzurrino*/
}

.ilovepoke .share {
  color: var(--primary);
  background: white;
}

.ilovepoke .subscription {
  color: white;
  background: var(--primary);
}

/* .ilovepoke .splash h1 {
  color: white;
} */

.ilovepoke .splash button {
  background: var(--tertiary);
  color: white;
}

.ilovepoke .splash a:nth-child(1) {
  background: var(--secondary);
  color: white;
}

.ilovepoke .splash a:nth-child(2) {
  color: var(--tertiary);
}

#menu-page
  > div.menu.relative.-mt-4.py-2.rounded-t-lg.bg-white
  > header
  > div.mb-4
  > div.cursor-pointer.p-1.rounded-md.bg-slate-100
  > div
  > div {
  background-color: white;
}

#menu-page
  > div.menu.relative.-mt-4.py-2.rounded-t-lg.bg-white
  > header
  > div.mb-4
  > div.cursor-pointer.p-1.rounded-md.bg-slate-100
  > div
  > a {
  color: var(--primary);
}

#menu-page
  > div.menu.relative.-mt-4.py-2.rounded-t-lg.bg-white
  > header
  > div.filter-toggle.my-4
  > div
  > div
  > button.flex-1.py-2.rounded-md.font-bold.text-sm.text-center.bg-mycia-red.text-white {
  background-color: var(--primary);
}

#menu-page
  > div.menu.relative.-mt-4.py-2.rounded-t-lg.bg-white
  > header
  > div.mb-4
  > div.w-full.mb-4.p-2.rounded-lg.border.border-slate-300
  > div
  > span {
  background: var(--primary);
}

.ilovepoke .restaurant-image {
  background: white;
}

.belliner img.restaurant-poster {
  height: 250px;
  object-fit: cover;
}

.belliner /*.courses-container*/ .menu {
  color: white;
  z-index: 1;
  background-color: transparent;
  margin-top: 4px;
}

.belliner section#menu-page {
  min-height: 100%;
}

.belliner section#menu-page::before {
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url("/public/assets/images/belliner.png");
  background-position: -5px 250px;
  filter: brightness(100%);
  background-size: 110% auto;
  box-sizing: border-box;
  z-index: -10;
}

.ilovepoke .collapsible-trigger {
  @apply mx-4;
  margin-bottom: 10px;
  margin-top: 10px;
  border: solid;
  border-radius: 10px;
  border-color: var(--primary);
  background: white;
}

.ilovepoke .collapsible-trigger.opened {
  @apply mx-4;
  margin-bottom: 0;
  background: var(--primary);
  color: white;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.ilovepoke .nested .collapsible-trigger.opened {
  color: black;
}

/* .ilovepoke .Collapsible div div {
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 8px;
  background: var(--primary);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
} */

/* .ilovepoke .Collapsible .nested {
  background: var(--primary);
} */

.ilovepoke .Collapsible .nested div {
  background: white;
  border: none;
  padding: 0.2rem;
}

.ilovepoke .dish-modal-trigger {
  color: var(--tertiary);
}

.ilovepoke .Collapsible__contentInner div div.nested {
  @apply bg-[var(--secondary)] mx-4 mb-[18px] p-4 rounded-b-lg shadow-lg;
}

.ilovepoke .drink div,
.ilovepoke .product,
.ilovepoke .dish div {
  background: white;
  @apply mb-1 rounded-lg;
}

.ilovepoke .dishes,
.ilovepoke .drinks {
  background: var(--primary);
  @apply mx-4 rounded-b-lg;
}

.ilovepoke .powered-by {
  color: var(--tertiary);
}

.belliner .all-black-bg {
  background-color: #323232;
}

.ilovepoke .tab {
  border: none;
  border-radius: 0;
}

.ilovepoke .tab-active {
  border: none;
  border-radius: 0;
  border-bottom: solid 2px var(--primary);
  background: white;
  color: black;
}

.ilovepoke .allergens-modal-trigger span {
  background: var(--primary);
  color: white;
}

.belliner .courses-container > header {
  border-color: #323232;
}

.ilovepoke .arrow-trigger {
  background-color: var(--primary);
  border: none;
  @apply shadow-none;
}

.belliner .product-content {
  overflow: hidden;
}

.belliner .bucket {
  color: #323232;
}

.belliner .preferred {
  background: rgba(1, 1, 1, 0.3);
}
