/* ---ADR--- */
/* Splashpage */

.adr .splash button {
  background: #064b94;
  color: white;
}
/*  */

/* Menupage  fork->list */
.adr .tab {
  background: #ffffff;
  border-color: #064b94;
  color: black;
}

.adr .tab-active {
  background: #064b94;
  border-color: #297d4d;
  color: white;
}

.adr .arrow-trigger {
  background: #c0c4c8;
  color: #0758a6;
  box-shadow: none;
}

.adr .cta {
  background: #297d4d;
}

.adr .cta > button {
  color: white;
}

.adr .filter-modal-trigger {
  background: #064b94;
  color: white;
}

.adr .subscription {
  background: #c0c4c8;
  color: #0758a6;
}

.adr .share {
  background: #c0c4c8;
  color: #0758a6;
}

.adr .bucket {
  background: #297d4d;
  color: white;
}

.adr .add-cart-item {
  background: #297d4d;
  color: white;
}

.adr .allergens-modal-trigger span {
  background: #297d4d;
  color: white;
}

.adr .cta-tasting-menu {
  background: #297d4d;
  color: white;
}
/*  */
