/* ---rossocardinale--- */
/* Splashpage */
.rossocardinale .splash button {
    background: #9E2B2B;
    color: white;
  }
  .rossocardinale .splash {
    color: white;
  }
  /*  */

  /* Menupage  fork->list */
  .rossocardinale .tab {
    background: #61594F;
    border-color: #61594F;
    color: white;
  }

  .rossocardinale .tab-active {
    background: #9E2B2B;
    border-color: #9E2B2B;
    color: white;
  }

  .rossocardinale .arrow-trigger {
    background: #bab5aa;
    color: #bab5aa;
    box-shadow: none;
  }

  .rossocardinale .cta {
    background: #434343;
  }

  .rossocardinale .filter-modal-trigger {
    background: #bab5aa;
    color: #bab5aa;
  }

  .rossocardinale .allergens-modal-trigger > span{
    background-color: #9E2B2B;
    color: white;
  }

  .rossocardinale .subscription {
    background: #434343;
    color: black;
  }
  
  .rossocardinale .share {
    background: #9E2B2B;
    color: white;
  }
  /*  */
  .rossocardinale .menu {
    background: #1c1c1c;
    color: white;
  }
  .rossocardinale .drinks {
    background-color: #bab5aa;
  }
  
  .rossocardinale .drinks.even {
    background-color: #1c1c1c;
  }
  
  .rossocardinale .drinks.odd {
    background-color: #1c1c1c;
  }
  
  .rossocardinale .dishes.even {
    background-color: #1c1c1c;
  }
  
  .rossocardinale .dishes.odd {
    background-color: #1c1c1c;
  }
  
  .rossocardinale .dish > div {
    background-color: #61594F;
    border: solid 0.5px #61594F;
  }

  .rossocardinale .drink > div{
      background: #61594F;
      border: solid 0.5px #61594F;
  }
  
  .rossocardinale .trigger.even {
    background-color: #1c1c1c;
    color: white;
  }
  
  .rossocardinale .trigger.odd {
    background-color: #1c1c1c;
    color: white;
  }
  
  .rossocardinale .arrow-trigger {
    background: #9E2B2B;
    color: white;
  }

  .rossocardinale .dish-modal-trigger{
      color:white;
  }

  .rossocardinale .drink-modal-trigger{
    color:white;
}

.rossocardinale #menu-page > div.menu.relative.-mt-4.py-2.rounded-t-lg.bg-white > header{
    border:none;
}
.rossocardinale .powered-by {
  color: white;
}
