.agave .splash {
    color: white;
}

.agave .splash button {
    background: #0E0A0C;
}

.agave #menu-page {
    background-image: url(/public/assets/images/agave.png);
    background-size: conver;
}

.agave .menu {
    background-color: rgba(255, 255, 255, 0);
    color: white;
    padding-top: 2rem;
}

.agave .list {
    background-color: rgba(255, 255, 255, 0);
    color: white;
    padding-top: 2rem;
}

.agave .react-tabs__tab-panel,
react-tabs__tab-panel--selected {
    color: white;
}

.agave .tab {
    border-color: #0E0A0C;
}

.agave .tab-active {
    background: #0E0A0C;
    border-color: #0E0A0C;
    color: white;
}

.agave .share {
    background: #0E0A0C;
    border-color: #0E0A0C;
    color: white;
}

.agave .filter-modal-trigger {
    background: #0E0A0C;
    color: white;
}

.agave .preferred-default-screen {
    background-color: rgba(255, 255, 255, 0);
    color: white;
}

.agave .drinks {
    background-color: rgba(255, 255, 255, 0.5);
}

.agave .drinks.even {
    background-color: rgba(255, 255, 255, 0);
    color: #0E0A0C;
}

.agave .drinks.odd {
    background-color: rgba(48, 90, 109, 0.2);
    color: #0E0A0C;
}

.agave .dishes.even {
    background-color: rgba(255, 255, 255, 0);
}

.agave .dishes.odd {
    background-color: rgba(48, 90, 109, 0.2);
}

.agave .dish>div {
    background-color: rgba(255, 255, 255, 0.8);
    color: #0E0A0C;
}

.agave .trigger.even {
    color: white;
    background-color: rgba(255, 255, 255, 0);
}

.agave .trigger.odd {
    background-color: rgba(48, 90, 109, 0.2);
}

.agave .drink>div {
    background-color: rgba(255, 255, 255, 0.8);
}

.agave .arrow-trigger {
    background: #0E0A0C;
}

.agave .powered-by {
    color: white;
}

.agave .product-content {
    color: #0E0A0C;
}

.agave .drink-content {
    color: #0E0A0C;
}

.agave .allergens-modal-trigger span {
    background-color: #0E0A0C;
}
