/* ---PANE E VINO--- */
/* Splashpage */

.pane-vino .splash button {
  background: #671d04;
  color: white;
}
/*  */

/* Menupage  fork->list */

.pane-vino .arrow-trigger {
  background: #671d04;
  color: white;
  box-shadow: none;
}

.pane-vino .cta {
  background: #df8444;
}

.pane-vino .cta button {
  color: white;
}

.pane-vino .filter-modal-trigger {
  background: #671d04;
  color: white;
}

.pane-vino .subscription {
  background: #671d04;
  color: white;
}

.pane-vino .share {
  background: #671d04;
  color: white;
}

.pane-vino .bucket {
  background: #df8444;
  color: white;
}

.pane-vino .bucket div {
  border-color: white;
  fill: white;
}

.pane-vino .add-cart-item {
  background: #df8444;
  color: white;
}

.pane-vino .allergens-modal-trigger span {
  background: #671d04;
  color: white;
}

/*  */
