/* ---MARS--- */
/* Splashpage */

.mars .splash button {
  background: #d75b1e;
  color: white;
}
/*  */

/* Menupage  fork->list */
.mars .tab {
  background: #ffffff;
  border-color: #d75b1e;
  color: black;
}

.mars .tab-active {
  background: #d75b1e;
  border-color: #d75b1e;
  color: white;
}

.mars .arrow-trigger {
  background: #d75b1e;
  color: white;
  box-shadow: none;
}

.mars .cta {
  background: #28a4e1;
}

.mars .cta > button {
  color: black;
}

.mars .filter-modal-trigger {
  background: #d75b1e;
  color: white;
}

.mars .subscription {
  background: #fede49;
  color: black;
}

.mars .share {
  background: #f5a900;
  color: black;
}

.mars .bucket {
  background: #28a4e1;
  color: black;
}

.mars .add-cart-item {
  background: #28a4e1;
  color: black;
}

.mars .allergens-modal-trigger span {
  background: #d75b1e;
  color: white;
}

.mars .cta-tasting-menu {
  background: #d75b1e;
  color: white;
}

.mars .details span {
  color: #d75b1e;
}

.mars .suggested-drink {
  background: #f2f2f2;
}

.mars .suggested-drink span {
  color: #28a4e1;
}

.mars .better-with label {
  color: #d75b1e;
}

.mars .better-with-button {
  border-color: #d75b1e;
}
/*  */
