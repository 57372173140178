/* ---LA MELA D'ORO--- */
/* Splashpage */

.mela-oro .splash button {
  background: #a0480f;
  color: white;
}
/*  */

/* Menupage  fork->list */

.mela-oro .arrow-trigger {
  background: #a0480f;
  color: white;
  box-shadow: none;
}

.mela-oro .cta {
  background: #fee9cf;
}

.mela-oro .filter-modal-trigger {
  background: #a0480f;
  color: white;
}

.mela-oro .subscription {
  background: #a0480f;
  color: white;
}

.mela-oro .share {
  background: #a0480f;
  color: white;
}

.mela-oro .bucket {
  background: #fee9cf;
  color: black;
}

.mela-oro .add-cart-item {
  background: #fee9cf;
  color: black;
}

.mela-oro .allergens-modal-trigger span {
  background: #a0480f;
  color: white;
}

/*  */
