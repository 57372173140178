/* ---Morgana--- */
/* Splashpage */
.morgana .splash {
  color: #886e47;
}

.morgana .splash button {
  background: #886e47;
}
/*  */

/* Menupage  fork->list */
.morgana .tab {
  background: #0c2835;
  border-color: #886e47;
  color: white;
}

.morgana .tab-active {
  background: #886e47;
  border-color: #886e47;
}

.morgana .arrow-trigger {
  background: #eb6440;
}

.morgana .cta {
  background: #886e47;
}

.morgana .cta button {
  color: white;
}

.morgana .wrap-course {
  color: #886e47;
}

.morgana .collapsible-trigger {
  background: #0c2835;
}

.morgana .list {
  background: #0c2835;
  color: white;
}

.morgana .product {
  background: #0c2835;
}

.morgana .product-content {
  background: white;
  color: black;
}

.morgana .drink {
  color: black;
}

.morgana .button-native {
  background: #eb6440;
}

.morgana .cta-tasting-menu {
  background: #886e47;
  color: white;
}

.morgana .allergens-modal-trigger span {
  background: #886e47;
}

.morgana .powered-by {
  color: white;
}

/*  */

/* Menupage  fork->menu */

/*  */
