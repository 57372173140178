/*.primo ion-content {
     --background: url('/public/assets/primo/bg.png') 0 100px/100% auto repeat;
     filter: brightness(40%);
 }*/

/* .primo ion-content {
     --background: #323232;
 } */

.belliner .share {
  color: black;
  background: white;
}

.belliner .splash h1 {
  color: white;
}

.belliner .splash button {
  background: #323232;
  color: white;
}

.belliner .restaurant-image {
  background: white;
}

.belliner img.restaurant-poster {
  height: 250px;
  object-fit: cover;
}

.belliner /*.courses-container*/ .menu {
  color: white;
  z-index: 1;
  background-color: transparent;
  margin-top: 4px;
}

.belliner section#menu-page {
  min-height: 100%;
}

.belliner section#menu-page::before {
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url("/public/assets/images/belliner.png");
  background-position: -5px 250px;
  filter: brightness(100%);
  background-size: 110% auto;
  box-sizing: border-box;
  z-index: -10;
}

.belliner .Collapsible .even {
  background-color: rgba(1, 1, 1, 0.2);
}

.belliner .Collapsible .odd {
  background-color: rgba(1, 1, 1, 0.6);
}

.belliner .allergens-modal-trigger {
  /* background-color: #323232; */
  padding: 1.5rem 1rem 1.5rem 1rem;
  margin: 0px;
}

.belliner .drink,
.belliner .product,
.belliner .dish {
  color: #323232;
}

.belliner .powered-by {
  /* background-color: #323232; */
  color: #323232;
}

.belliner .all-black-bg {
  background-color: #323232;
}

.belliner .tab {
  background-color: white;
  color: #323232;
  opacity: 0.9;
  border: none;
}

.belliner .tab-active {
  background-color: #323232;
  color: white;
  opacity: 1;
}

.belliner .allergens-modal-trigger span {
  background-color: #323232;
  color: white;
}

.belliner .courses-container > header {
  border-color: #323232;
}

.belliner .arrow-trigger {
  background-color: #323232;
}

.belliner .product-content {
  overflow: hidden;
}

.belliner .bucket {
  color: #323232;
}

.belliner .preferred {
  background: rgba(1, 1, 1, 0.3);
}
