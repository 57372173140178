/* ---SUSHITAO--- */
/* Splashpage */
.sushitao .splash {
  color: #886e47;
}

.sushitao .splash button {
  background: #886e47;
}
/*  */

/* Menupage  fork->list */
.sushitao .tab {
  background: #0c2835;
  border-color: #886e47;
  color: white;
}

.sushitao .tab-active {
  background: #886e47;
  border-color: #886e47;
}

.sushitao .arrow-trigger {
  background: #eb6440;
}

.sushitao .cta {
  background: #886e47;
}

.sushitao .cta button {
  color: white;
}

.sushitao .collapsible-trigger {
  background: #0c2835;
}

.sushitao .list {
  background: #0c2835;
  color: white;
}

.sushitao .product {
  background: #0c2835;
}

.sushitao .product-content {
  background: white;
  color: black;
}

.sushitao .drink {
  color: black;
}

.sushitao .button-native {
  background: #eb6440;
}

.sushitao .cta-tasting-menu {
  background: #886e47;
  color: white;
}

.sushitao .allergens-modal-trigger span {
  background: #886e47;
}

.sushitao .powered-by {
  color: white;
}

/*  */

/* Menupage  fork->menu */

/*  */
