/* ---Bluenote--- */
/* Splashpage */
.bluenote h1 {
  color: white;
  font: normal normal bold 30px/48px Nunito;
}

.bluenote .splash button {
  font-weight: bold;
  background: #c6aa5c;
  color: white;
  border-radius: 30px;
}
/*  */

/* Menupage  fork->list */
.bluenote .filter-modal-trigger {
  background: #c6aa5c;
  color: white;
}

.bluenote .collapsible-trigger.trigger {
  background: rgba(0, 0, 0, 0);
  color: white;
}

.bluenote .collapsible-trigger.trigger.opened {
  background: #0e1e2c;
  color: white;
}

.bluenote .powered-by {
  color: white;
}

.bluenote .tab {
  border-color: #c6aa5c;
  color: white;
}

.bluenote .share {
  background-color: white;
  color: black;
}

.bluenote .tab-active {
  background: #c6aa5c;
  border-color: #c6aa5c;
  color: white;
}

.bluenote .arrow-trigger {
  background: #c6aa5c;
  color: white;
}

.bluenote .allergens-modal-trigger span {
  background: #c6aa5c;
  color: white;
}
/*  */

/* Menupage  fork->menu */
.bluenote .menu,
.bluenote .reviews-list {
  background: rgba(0, 0, 0, 0.4);
  color: white;
}

.bluenote .dish,
.bluenote .review-item {
  background: #0e1e2c;
  color: black;
}

.bluenote .dish-modal-trigger,
.bluenote .drink-modal-trigger {
  color: #335f46;
}

.bluenote .drink {
  color: black;
  background: #0e1e2c;
}

.bluenote .preferred-default-screen {
  background: #0e1e2c;
}

.bluenote .allergens-modal-content h1 {
  color: black;
}

.bluenote .vote {
  background: #c6aa5c;
}
/*  */
