/* ---Ultra Brasserie--- */
/* Splashpage */
.ultrabrasserie .splash {
  color: red;
}

.ultrabrasserie .splash button {
  background: #f70000;
}
/*  */

/* Menupage  fork->list */
.ultrabrasserie .filter-modal-trigger {
  background: black;
  color: white;
}

.ultrabrasserie .tab {
  background: #faf7f0;
  border-color: black;
  color: black;
}

.ultrabrasserie .share {
  background-color: white;
  color: black;
}

.ultrabrasserie .tab-active {
  background: black;
  border-color: black;
  color: white;
}

.ultrabrasserie .arrow-trigger {
  background: white;
  box-shadow: black;
  color: black;
}

.ultrabrasserie span div {
  background: #faf7f0;
  color: black;
}

.ultrabrasserie .odd {
  background: #007e7d;
  color: white;
}

.ultrabrasserie .dishes {
  background: #faf7f0;
  color: black;
}

.ultrabrasserie .allergens-modal-trigger span {
  background: black;
  color: white;
}
/*  */

/* Menupage  fork->menu */
.ultrabrasserie .menu {
  background: #faf7f0;
  color: black;
}

.ultrabrasserie .dish {
  background: #faf7f0;
  color: black;
}

.ultrabrasserie .dish div {
  background: white;
  color: black;
  border-radius: 10px;
}

.ultrabrasserie .odd .dish {
  background: #007e7d;
  color: black;
}

.ultrabrasserie .dish-modal-trigger,
.ultrabrasserie .drink-modal-trigger {
  color: #03012c;
}

.ultrabrasserie .drink {
  color: black;
}

.ultrabrasserie .even .drink {
  background: #faf7f0;
  color: black;
}

.ultrabrasserie .odd .drink {
  background: #007e7d;
  color: black;
}

.ultrabrasserie .drink div {
  background: white;
  border-radius: 10px;
}
/*  */
