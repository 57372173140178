.legend-modal-trigger .modal-wrapper {
  width: 23em;
  height: 34em;
  border-radius: 0.5rem;
  padding-bottom: 0rem;
}

.legend-modal-trigger .modal-wrapper .legend-modal-content {
  padding: 0px 20px 0px 20px;
}
