/*.almaluna ion-content {
     --background: url('/public/assets/almaluna/bg.png') 0 100px/100% auto repeat;
     filter: brightness(40%);
 }*/

/* .almaluna ion-content {
     --background: #323232;
 } */

.almaluna .splash h1 {
  color: white;
}

.almaluna .splash button {
  background: #f4aa3d;
  color: black;
}

.almaluna .restaurant-image {
  background: white;
}

.almaluna img.restaurant-poster {
  height: 350px;
  object-fit: cover;
}

.almaluna /*.courses-container*/ .list {
  color: white;
  z-index: 1;
  background-color: transparent;
  margin-top: 4px;
}

.almaluna section#menu-page {
  min-height: 100%;
}

.almaluna section#menu-page::before {
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url("/public/assets/images/almaluna.png");
  background-position: 0px 250px;
  filter: brightness(60%);
  background-size: 100% auto;
  box-sizing: border-box;
  z-index: -10;
}

.almaluna .Collapsible .even {
  background: transparent;
}

.almaluna .Collapsible .odd {
  background-color: black;
}

.almaluna .allergens-modal-trigger {
  /* background-color: #323232; */
  padding: 1.5rem 1rem 1.5rem 1rem;
  margin: 0px;
}

.almaluna .drink,
.almaluna .product {
  color: black;
}

.almaluna .powered-by {
  /* background-color: #323232; */
  color: white;
}

.almaluna .all-black-bg {
  background-color: black;
}

.almaluna .tab {
  background-color: transparent;
  color: white;
  opacity: 1;
  border: solid 1px #f4aa3d;
}

.almaluna .tab-active {
  background-color: #f4aa3d;
  color: black;
  opacity: 1;
}

.almaluna .allergens-modal-trigger span {
  background-color: #f4aa3d;
  color: black;
}

.almaluna .courses-container > header {
  border-color: #323232;
}

.almaluna .arrow-trigger {
  background-color: #0e0a0c;
}

.almaluna .product-content {
  overflow: hidden;
}

.almaluna .bucket {
  color: black;
}

button.share {
  background-color: black;
  color: white;
}
