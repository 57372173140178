.barriobotanico .splash {
  color: white;
}

.barriobotanico .splash button {
  background: #2b7e6a;
  color: white;
}

.barriobotanico #menu-page {
  background-image: url(/public/assets/images/barriobotanico.png);
}

.barriobotanico .menu {
  background-color: rgba(255, 255, 255, 0);
  color: white;
  padding-top: 2rem;
}

.barriobotanico .list {
  background-color: rgba(255, 255, 255, 0);
  color: white;
  padding-top: 2rem;
}

.barriobotanico .react-tabs__tab-panel,
react-tabs__tab-panel--selected {
  color: black;
}

.barriobotanico .tab {
  background: #ffffff;
  border-color: #ffffff;
  color: black;
}

.barriobotanico .tab-active {
  background: #2b7e6a;
  border-color: #2b7e6a;
  color: white;
}

.barriobotanico .share {
  background: #228e38;
  border-color: #228e38;
  color: white;
}

.barriobotanico .preferred-default-screen {
  background-color: rgba(255, 255, 255, 0);
  color: white;
}

.barriobotanico .drinks {
  background-color: rgba(255, 255, 255, 0.5);
}

.barriobotanico .drinks.even {
  background-color: rgba(255, 255, 255, 0);
}

.barriobotanico .drinks.odd {
  background-color: rgba(255, 255, 255, 0.5);
}

.barriobotanico .dishes.even {
  background-color: rgba(255, 255, 255, 0);
}

.barriobotanico .dishes.odd {
  background-color: rgba(255, 255, 255, 0.5);
}

.barriobotanico .dish>div {
  background-color: rgba(255, 255, 255, 0.8);
}

.barriobotanico .trigger.even {
  color: white;
  background-color: rgba(255, 255, 255, 0);
}

.barriobotanico .trigger.odd {
  background-color: rgba(255, 255, 255, 0.5);
}

.barriobotanico .drink>div {
  background-color: rgba(255, 255, 255, 0.8);
}

.barriobotanico .arrow-trigger {
  background: #2b7e6a;
}

.barriobotanico .powered-by {
  color: white;
}

.barriobotanico .allergens-modal-trigger span {
  background-color: #2b7e6a;
}
