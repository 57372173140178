/* ---ZIGOZAGO--- */

/* Splashpage */
.zigozago h1 {
  color: #39aff9;
}

.zigozago .splash button {
  background: #39aff9;
  color: white;
}
/*  */

/* Menupage  fork->list */
.zigozago .menu,
.zigozago .menu h1 {
  background: #eae2d7;
  color: black;
}

.zigozago .filter-modal-trigger {
  background: #39aff9;
  color: white;
}

.zigozago .collapsible-trigger.trigger {
  background: #eae2d7;
  color: black;
}

.zigozago .powered-by {
  color: black;
}

.zigozago .tab {
  background: #eae2d7;
  border-color: #39aff9;
  color: black;
}

.zigozago .share {
  background-color: #39aff9;
  color: white;
}

.zigozago .tab-active {
  background: #39aff9;
  border-color: #eae2d7;
  color: white;
  border-width: 2px;
}

.zigozago .arrow-trigger {
  background: #39aff9;
  color: white;
}

.zigozago .allergens-modal-trigger span {
  background: #39aff9;
  color: white;
}
/*  */

/* Menupage  fork->menu */
.zigozago .dish,
.zigozago .drink {
  background: #eae2d7;
  color: black;
}

.zigozago .dish div,
.zigozago .drink div {
  background: #c1b59f;
  color: black;
  border-radius: 10px;
}

.zigozago .dish-modal-trigger,
.zigozago .drink-modal-trigger {
  color: #eae2d7;
}
/*  */
