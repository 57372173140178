/* ---fattoriegarofalo--- */
/* primary:#ffffff secondary:#212b4d piatto:#8aa8bd

/* Splashpage */
.fattoriegarofalo h1 {
  color: #212b4d;
}

.fattoriegarofalo .splash button {
  background: #212b4d;
  color: white;
}
/*  */

/* Menupage  fork->list */
.fattoriegarofalo .menu,
.fattoriegarofalo .menu h1 {
  background: #ffffff;
  color: #212b4d;
}

.fattoriegarofalo .filter-modal-trigger {
  background: #212b4d;
  color: white;
}

.fattoriegarofalo .collapsible-trigger.trigger {
  background: white;
  color: #212b4d;
}

.fattoriegarofalo .powered-by {
  color: #212b4d;
}

.fattoriegarofalo .tab {
  background: white;
  border-color: #212b4d;
  color: #212b4d;
}

.fattoriegarofalo .tab-active {
  background: #212b4d;
  border-color: rgb(162, 166, 152);
  color: white;
}

.fattoriegarofalo .share {
  background-color: #212b4d;
  color: white;
}

.fattoriegarofalo .arrow-trigger {
  background: #212b4d;
  color: white;
}

.fattoriegarofalo .allergens-modal-trigger span {
  background: #212b4d;
  color: white;
}
/*  */

/* Menupage  fork->menu */
.fattoriegarofalo .dish,
.fattoriegarofalo .drink {
  background: white;
  color: black;
}

.fattoriegarofalo .dish div,
.fattoriegarofalo .drink div {
  background: #8aa8bd;
  color: #212b4d;
  border-radius: 10px;
}

.fattoriegarofalo .dish-modal-trigger,
.fattoriegarofalo .drink-modal-trigger {
  color: #212b4d;
}
/*  */
