/* ---tascaro--- */
/* Splashpage */
.tascaro .splash button {
  background: #434343;
  color: #bab5aa;
}
/*  */

/* Menupage  fork->list */
.tascaro .tab {
  background: #bab5aa;
  border-color: #434343;
  color: #434343;
}

.tascaro .tab-active {
  background: #434343;
  border-color: #434343;
  color: #bab5aa;
}

.tascaro .arrow-trigger {
  background: #bab5aa;
  color: #bab5aa;
  box-shadow: none;
}

.tascaro .cta {
  background: #434343;
}

.tascaro .filter-modal-trigger {
  background: #bab5aa;
  color: #bab5aa;
}

.tascaro .allergens-modal-trigger > span {
  background-color: #434343;
  color: #bab5aa;
}

.tascaro .subscription {
  background: #434343;
  color: #bab5aa;
}

.tascaro .share {
  background: #434343;
  color: #bab5aa;
}
/*  */
.tascaro .menu {
  background: #bab5aa;
  color: #434343;
}
.tascaro .drinks {
  background-color: #bab5aa;
}

.tascaro .drinks.even {
  background-color: #bab5aa;
}

.tascaro .drinks.odd {
  background-color: #bab5aa;
}

.tascaro .dishes.even {
  background-color: #bab5aa;
}

.tascaro .dishes.odd {
  background-color: #bab5aa;
}

.tascaro .dish > div {
  background-color: #e1e0dc;
  border: solid 0.5px #434343;
}

.tascaro .drink > div {
  background: #e1e0dc;
  border: solid 0.5px #434343;
}

.tascaro .trigger.even {
  color: #434343;
  background-color: #bab5aa;
}

.tascaro .trigger.odd {
  background-color: #aea99e;
  color: #434343;
}

.tascaro .arrow-trigger {
  background: #434343;
}

.tascaro .dish-modal-trigger {
  color: black;
}

.tascaro .drink-modal-trigger {
  color: black;
}

.tascaro
  #menu-page
  > div.menu.relative.-mt-4.py-2.rounded-t-lg.bg-white
  > header {
  border: none;
}
