.custom-cta .modal-wrapper {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    margin-top: 15vh;
    margin-left:1rem;
    margin-right:1rem;
    color: black;
}
