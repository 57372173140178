/* ---pizzeriadamichele--- */
/* #CD9C63 - #EABF8D - #EED4B5 - #543F25 - #B1966E  */
/* Splashpage */
.pizzeriadamichele *,
.pizzeriadamichele h1 {
  font-family: "Handlee", serif;
  font-weight: 900;
  font-style: normal;
}

.pizzeriadamichele .splash button {
  background: #543f25;
  color: white;
}
.pizzeriadamichele .splash {
  color: white;
}
/*  */

/* Menupage  fork->list */
.pizzeriadamichele .tab {
  background: #eabf8d;
  border-color: #eabf8d;
  color: white;
}

.pizzeriadamichele .tab-active {
  background: #543f25;
  border-color: #543f25;
  color: white;
}

.pizzeriadamichele .arrow-trigger {
  background: #bab5aa;
  color: #bab5aa;
  box-shadow: none;
}

.pizzeriadamichele .cta {
  background: #d7af81;
}

.pizzeriadamichele .filter-modal-trigger {
  background: #bab5aa;
  color: #bab5aa;
}

.pizzeriadamichele .allergens-modal-trigger > span,
.pizzeriadamichele .vote {
  background-color: #543f25;
  color: white;
}

.pizzeriadamichele .subscription {
  background: #d7af81;
  color: white;
}

.pizzeriadamichele .share {
  background: #543f25;
  color: white;
}
/*  */
.pizzeriadamichele .menu {
  background: #d7af81;
  color: white;
}
.pizzeriadamichele .drinks {
  background-color: #bab5aa;
}

.pizzeriadamichele .drinks.even {
  background-color: #d7af81;
}

.pizzeriadamichele .drinks.odd {
  background-color: #d7af81;
}

.pizzeriadamichele .dishes.even,
.pizzeriadamichele .reviews-list {
  background-color: #d7af81 !important;
}

.pizzeriadamichele .dishes.odd {
  background-color: #d7af81;
}

.pizzeriadamichele .dish > div,
.pizzeriadamichele .review-item > div {
  background-color: #fcfcfc;
  border: solid 0.5px #fcfcfc;
  color: #543f25;
}

.pizzeriadamichele .drink > div {
  background: #fcfcfc;
  border: solid 0.5px #fcfcfc;
  color: #543f25;
}

.pizzeriadamichele .trigger.even {
  background-color: #d7af81;
  color: white;
}

.pizzeriadamichele .trigger.odd {
  background-color: #d7af81;
  color: white;
}

.pizzeriadamichele .arrow-trigger {
  background: #543f25;
  color: white;
}

.pizzeriadamichele .dish-modal-trigger {
  color: #543f25;
}

.pizzeriadamichele .drink-modal-trigger {
  color: #543f25;
}

.pizzeriadamichele
  #menu-page
  > div.menu.relative.-mt-4.py-2.rounded-t-lg.bg-white
  > header {
  border: none;
}
.pizzeriadamichele .powered-by {
  color: white;
}
