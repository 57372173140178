/* ---MACELLERIA IL CACCIATORE--- */
/* Splashpage */

.il-cacciatore .splash button {
  background: #fabf3e;
  color: #671d04;
}
/*  */

/* Menupage  fork->list */

.il-cacciatore .arrow-trigger {
  background: #fabf3e;
  box-shadow: none;
  color: black;
}

.il-cacciatore .cta {
  background: #b03b0a;
}

.il-cacciatore .cta button {
  color: white;
}

.il-cacciatore .filter-modal-trigger {
  background: #fabf3e;
  color: #671d04;
}

.il-cacciatore .subscription {
  background: #fabf3e;
  color: #671d04;
}

.il-cacciatore .share {
  background: #fabf3e;
  color: #671d04;
}

.il-cacciatore .bucket {
  background: #b03b0a;
  color: white;
}

.il-cacciatore .bucket div {
  border-color: white;
  fill: white;
}

.il-cacciatore .add-cart-item {
  background: #b03b0a;
  color: white;
}

.il-cacciatore .allergens-modal-trigger span {
  background: #fabf3e;
  color: #671d04;
}

/*  */
