/* ---MCDONALD--- */
/* Splashpage */

.mcdonald .splash button {
  background: #25450c;
  color: white;
}
/*  */

/* Menupage  fork->list */
.mcdonald .product-content {
  background: white;
}

.mcdonald .tab {
  background: #ffffff;
  border-color: #940f20;
  color: black;
}

.mcdonald .tab-active {
  background: #940f20;
  border-color: #940f20;
  color: white;
}

.mcdonald .arrow-trigger {
  background: #fede49;
  color: black;
  box-shadow: none;
}

.mcdonald .cta {
  background: #25450c;
}

.mcdonald .cta > button {
  color: white;
}

.mcdonald .filter-modal-trigger {
  background: #25450c;
  color: white;
}

.mcdonald .subscription {
  background: #fede49;
  color: black;
}

.mcdonald .share {
  background: #fede49;
  color: black;
}

.mcdonald .bucket {
  background: #25450c;
  color: white;
}

.mcdonald .add-cart-item {
  background: #f2efef;
  color: black;
}

.mcdonald .allergens-modal-trigger span {
  background: #940f20;
  color: white;
}

.mcdonald .cta-tasting-menu {
  background: #25450c;
  color: white;
}

.mcdonald .add-cart-item {
  background: #25450c;
  color: white;
}

.mcdonald .details span {
  color: #25450c;
}

.mcdonald .suggested-drink {
  background: white;
}

.mcdonald .suggested-drink span {
  color: #940f20;
}

.mcdonald .better-with label {
  color: #d75b1e;
}

.mcdonald .better-with-button {
  border-color: #d75b1e;
}
/*  */
