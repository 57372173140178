/* ---Botanico--- */
/* Splashpage */
.botanico h1 {
  color: white;
}
.botanico .splash {
  color: white;
}
.botanico .splash button {
  background: #f5f5dc;
  color: black;
}
/*  */

/* Menupage  fork->list */
.botanico .filter-modal-trigger {
  background: #f5f5dc;
  color: black;
}

.botanico .collapsible-trigger.trigger {
  background: #005842;
  color: white;
}

.botanico .powered-by {
  color: white;
}

.botanico .tab {
  background: #f5f5dc;
  border-color: #f5f5dc;
  color: black;
}

.botanico .share {
  background-color: #f5f5dc;
  color: black;
}

.botanico .tab-active {
  background: black;
  border-color: black;
  color: white;
}

.botanico .arrow-trigger {
  background: #f5f5dc;
  color: #005842;
}

.botanico .allergens-modal-trigger span {
  background: #f5f5dc;
  color: black;
}
/*  */

/* Menupage  fork->menu */
.botanico .menu {
  background: #005842;
  color: white;
}

.botanico .dish {
  background: #005842;
  color: black;
}

.botanico .dish div {
  background: white;
  color: black;
  border-radius: 10px;
}

.botanico .odd .dish {
  background: #005842;
  color: black;
}

.botanico .dish-modal-trigger,
.botanico .drink-modal-trigger {
  color: #005842;
}

.botanico .drink {
  color: black;
  background: #005842;
}
/*  */
