/* ---Sanpellegrino--- */
/* Splashpage */
.sanpellegrino .splash {
  color: white;
  font-weight: 700;
}

.sanpellegrino .splash button {
  color: white;
  font-weight: 700;
  background: #f12936;
  border-radius: 30px;
  padding: 1rem;
}

/* .sanpellegrino .splash button:nth-child(3) {
    color: white;
    font-weight: 700;
    background: #E10B25;
} */

/*  */

.sanpellegrino .restaurant-image {
  background: white;
  padding: 2rem 2rem 4rem 2rem;
}

.sanpellegrino header h1 {
  font-weight: 700;
}

/* Menupage  fork->list */
.sanpellegrino .tab {
  background: white;
  border-color: white;
  color: black;
}

.sanpellegrino .tab-active {
  background: #f12936;
  border-color: #f12936;
  color: white;
}

.sanpellegrino .share {
  background-color: #f12936;
}

.sanpellegrino .subscription {
  background-color: #f12936;
}

.sanpellegrino .arrow-trigger {
  background: #e7e7e7;
  box-shadow: none;
  color: black;
}

/* -----------Custom collapsibles----------- */
.sanpellegrino .sanbitter {
  background-color: white !important;
  color: black !important;
  font-weight: 700;
}

.sanpellegrino .pago {
  background-color: black !important;
  color: white !important;
}

.sanpellegrino .drinks.sanbitter-bg {
  background: url("/public/assets/sanbitter/sanbitter-bg.png") !important;
  object-fit: contain !important;
  background-repeat: none !important;
}

.sanpellegrino .drinks.pago-bg {
  background: url("/public/assets/sanbitter/drinks-bg.png") 50% 50% !important;
  object-fit: contain !important;
  background-repeat: none !important;
}
/* ---------------------- */

.sanpellegrino .Collapsible .even {
  font-weight: 700;
  background: #ffffffe6;
  color: black;
}

.sanpellegrino .Collapsible .odd {
  background: #002b5c;
  color: white;
}

.sanpellegrino .dishes {
  background: #be2840;
  color: black;
}

.sanpellegrino .dish {
  color: black;
}

.sanpellegrino .list {
  background: #002b5c;
  color: white;
}

.sanpellegrino .menu {
  background: #002b5c;
  color: white;
}

.sanpellegrino .products .even {
  background: white;
  color: black;
}

.sanpellegrino .product-content {
  color: black;
}

.sanpellegrino .drink {
  padding: 1rem;
  color: black;
}

.sanpellegrino .drink > div {
  background: white;
}

.sanpellegrino .drink-content {
  background: white;
}

.sanpellegrino .cta {
  background: #dc182e !important;
  font-weight: 700;
  color: white;
}

.sanpellegrino .bucket {
  background: #e10b25;
  font-weight: 700;
}

.sanpellegrino .cta button {
  color: white;
}

.sanpellegrino .bucket button {
  color: white;
}

.sanpellegrino .filter-modal-trigger {
  border-color: #032a5c;
  padding: 1rem;
  color: black;
  background: white;
  border-width: 2px;
  font-weight: 700;
}

.sanpellegrino .fixed-cart {
  background: #f12936;
  color: white;
}

.sanpellegrino .add-cart-item {
  background: #f12936;
  font-weight: 700;
  color: white;
}

.sanpellegrino .product .dish-modal-trigger {
  color: #be2840;
}

.sanpellegrino .dish .dish-modal-trigger {
  color: #be2840;
}

.sanpellegrino .drink-modal-trigger {
  color: #be2840;
}

.sanpellegrino .allergens-modal-trigger span {
  border-color: #032a5c;
  padding: 1rem;
  color: black;
  background: white;
  border-width: 2px;
  font-weight: 700;
}

.sanpellegrino .powered-by {
  color: white;
}

.sanpellegrino
  #orders-page
  > div
  > div.w-full.flex.flex-col.justify-center.p-4
  > button {
  background-color: #be2840;
  color: white;
}

.sanpellegrino #tables-page button[type="submit"] {
  background: #be2840;
  opacity: 1;
  color: white;
}

.sanpellegrino #takeaway-page input[type="submit"] {
  background: #be2840;
  opacity: 1;
  color: white;
}

.sanpellegrino
  #root
  > ion-app
  > ion-router-outlet
  > div:nth-child(4)
  > ion-content
  > div.w-full.flex.flex-col.justify-center.mt-8.p-4
  > button {
  background: #be2840;
  color: white;
}

.sanpellegrino
  ion-content
  > div.p-3
  > div.mt-4.pb-6.rounded-lg.bg-white
  > div.mt-4.mx-3.rounded-lg.bg-grey-200.border-solid.border-l-8.border-mycia-green.font-bold {
  border-color: red;
}

.sanpellegrino .delivery-popup-modal-content div:last-child {
  background: #be2840;
  color: white;
}
