.vanquish .splash {
  color: white;
}

.vanquish .splash button {
  background: #5898e4;
  color: white;
}

.vanquish #menu-page {
  background-image: url(/public/assets/images/vanquish.png);
}

.vanquish .menu {
  background-color: rgba(255, 255, 255, 0);
  color: white;
  padding-top: 2rem;
}

.vanquish .list {
  background-color: rgba(255, 255, 255, 0);
  color: white;
  padding-top: 2rem;
}

.vanquish .react-tabs__tab-panel,
react-tabs__tab-panel--selected {
  color: black;
}

.vanquish .tab {
  background: #ffffff;
  border-color: #ffffff;
  color: black;
}

.vanquish .tab-active {
  background: #5898e4;
  border-color: #5898e4;
  color: white;
}

.vanquish .share {
  background: #5898e4;
  border-color: #5898e4;
  color: white;
}

.vanquish .preferred-default-screen {
  background-color: rgba(255, 255, 255, 0);
  color: white;
}

.vanquish .drinks {
  background-color: rgba(255, 255, 255, 0.5);
}

.vanquish .drinks.even {
  background-color: rgba(255, 255, 255, 0.0);
}

.vanquish .drinks.odd {
  background-color: rgba(255, 255, 255, 0.8);
}

.vanquish .dishes.even {
  background-color: rgba(255, 255, 255, 0);
}

.vanquish .dishes.odd {
  background-color: rgba(255, 255, 255, 0.8);
}

.vanquish .dish>div {
  background-color: rgba(255, 255, 255, 0.9);
}

.vanquish .trigger.even {
  color: white;
  background-color: rgba(255, 255, 255, 0);
}

.vanquish .trigger.odd {
  background-color: rgba(255, 255, 255, 0.8);
}

.vanquish .drink>div {
  background-color: rgba(255, 255, 255, 0.9);
}

.vanquish .arrow-trigger {
  background: #5898e4;
}

.vanquish .powered-by {
  color: white;
}

.vanquish .allergens-modal-trigger span {
  background-color: #5898e4;
}
