/* ---MEZZOSPIRITO--- */

/* Splashpage */
.mezzospirito h1 {
  color: white;
}

.mezzospirito .splash button {
  background: rgb(162, 166, 152);
  color: black;
}
/*  */

/* Menupage  fork->list */
.mezzospirito .menu,
.mezzospirito .menu h1 {
  background: rgb(27, 63, 54);
  color: white;
}

.mezzospirito .filter-modal-trigger {
  background: rgb(162, 166, 152);
  color: rgb(27, 63, 54);
}

.mezzospirito .collapsible-trigger.trigger {
  background: rgb(27, 63, 54);
  color: white;
}

.mezzospirito .powered-by {
  color: white;
}

.mezzospirito .tab {
  background: rgb(27, 63, 54);
  border-color: rgb(162, 166, 152);
  color: rgb(162, 166, 152);
}

.mezzospirito .tab-active {
  background: rgb(162, 166, 152);
  border-color: rgb(162, 166, 152);
  color: rgb(27, 63, 54);
  border-width: 2px;
}

.mezzospirito .share {
  background-color: rgb(162, 166, 152);
  color: white;
}

.mezzospirito .arrow-trigger {
  background: rgb(162, 166, 152);
  color: white;
}

.mezzospirito .allergens-modal-trigger span {
  background: rgb(240, 233, 217);
  color: rgb(27, 63, 54);
}
/*  */

/* Menupage  fork->menu */
.mezzospirito .dish,
.mezzospirito .drink {
  background: rgb(240, 233, 217);
  color: black;
}

.mezzospirito .dish div,
.mezzospirito .drink div {
  background: white;
  color: black;
  border-radius: 10px;
}

.mezzospirito .dish-modal-trigger,
.mezzospirito .drink-modal-trigger {
  color: rgb(27, 63, 54);
}
/*  */
