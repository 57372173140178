/* ---Cocacola--- */
/* Splashpage */
.cocacola .splash button {
  background: #f70000;
}
/*  */

/* Menupage  fork->list */
.cocacola .tab {
  background: #f3c8be;
  border-color: #f3c8be;
}

.cocacola .tab-active {
  background: #f70000;
}

.cocacola .arrow-trigger {
  background: black;
}

.cocacola .cta {
  background: #f70000;
}

.cocacola .collapsible-trigger {
  background: #e6e6e5;
}

.cocacola .list {
  background: #e6e6e5;
}

.cocacola .product {
  background: #e6e6e5;
}

.cocacola .product-content {
  background: #f3c8be;
}

.cocacola .button-native {
  background: black;
}

.cocacola .cta-tasting-menu {
  background: #f70000;
}

.cocacola .allergens-modal-trigger span {
  background: #7d7c7c;
}
/*  */

/* Menupage  fork->menu */

/*  */
