/*https://github.com/ionic-team/ionic-framework/issues/27599 - mobile fix*/
[popover].popover-viewport {
    display: initial;
    position: initial;
    margin: initial;
    border: initial;
    background: initial;
    padding: initial;
    width: initial;
    height: initial;
    overflow: initial;
    inset: initial;
    color: black;
}

/*https://github.com/ionic-team/ionic-framework/issues/15036 - desktop fix*/
.custom-popover .popover-content {
    --width: 95%;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
}
