/* ---CAPANNINA--- */
/* Splashpage */
.letorri .splash {
    color: white;
}

/*  */

/* Menupage  fork->list */
.letorri .menu {
    background-color: black;
    color: white;
}

.letorri .collapsible-trigger {
    background-color: black;
}

/*  */

.letorri .drinks {
    background-color: black;
    color: black;
}

.letorri .dishes {
    background-color: black;
    color: black;
}

.letorri .powered-by {
    color: white;
}
