/* ---SAN PELLEGRINO--- */
/* Splashpage */

.giappugliese .splash button {
    background: #DEB660;
    color: black;
}

.giappugliese ion-content {
    --background: black;
}

.giappugliese .splash {
    color: white;
}

/*  */

/* Menupage  fork->menu */
.giappugliese .menu.relative.-mt-4.py-2.rounded-t-lg.bg-white {
    background: black;
    color: white;
}

.giappugliese .collapsible-trigger {
    background: black;
}

.giappugliese .tab {
    background: black;
    border-color: #DEB660;
    color: white;
}

.giappugliese .tab-active {
    background: #DEB660;
    border-color: #DEB660;
    color: black;
}

.giappugliese .arrow-trigger {
    background: #DEB660;
    color: black;
    box-shadow: none;
}

.giappugliese .filter-modal-trigger {
    border-color: #DEB660;
    color: black;
    background: #DEB660;
}

.giappugliese .subscription {
    background: #DEB660;
    color: black;
}

.giappugliese .share {
    background: #DEB660;
    color: black;
}

.giappugliese .bucket {
    background: #DEB660;
    color: black;
}

.giappugliese .add-cart-item {
    background: #DEB660;
    color: black;
}

.giappugliese .allergens-modal-trigger span {
    background: #DEB660;
    color: black;
}

.giappugliese .dish-modal-trigger {
    color: #DEB660;
}

/* .giappugliese .dishes.even.bg-white {
    background: #505050;
} */

.giappugliese .dishes {
    background: black;
}

.giappugliese .dish>div {
    background: #464646;
}

.giappugliese .drinks {
    background: black;
}

.giappugliese .drink>div {
    background: #464646;
}

.giappugliese .product-content {
    background: #464646;
}

.giappugliese .dishes.odd .dish div.flex.rounded-lg.shadow.bg-grey-100.rounded-b-none {
    background: #00000029;
}

/*  */
