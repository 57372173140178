.update-account-modal-trigger .modal-wrapper {
  width: 23em;
  height: 14em;
  border-radius: 0.5rem;
  padding-bottom: 0rem;
}

.update-account-modal-trigger .modal-wrapper .update-account-modal-content {
  padding: 40px 20px 0px 20px;
}
