/*.primo ion-content {
     --background: url('/public/assets/primo/bg.png') 0 100px/100% auto repeat;
     filter: brightness(40%);
 }*/

/* .primo ion-content {
     --background: #323232;
 } */

.venere .share {
  color: black;
  background: white;
}

.venere .splash h1 {
  color: white;
}

.venere .splash button {
  background: white;
  color: black;
}

.venere .restaurant-image {
  background: white;
}

.venere img.restaurant-poster {
  height: 250px;
  object-fit: cover;
}

.venere /*.courses-container*/ .menu {
  color: white;
  z-index: 1;
  background-color: transparent;
  margin-top: 4px;
}

.venere section#menu-page {
  min-height: 100%;
}

.venere section#menu-page::before {
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url("/public/assets/images/venere.png");
  background-position: -5px 250px;
  filter: brightness(80%);
  background-size: 110% auto;
  box-sizing: border-box;
  z-index: -10;
}

.venere .Collapsible .even {
  background: transparent;
}

.venere .Collapsible .odd {
  background-color: white;
  color: black;
  opacity: 0.9;
}

.venere .allergens-modal-trigger {
  /* background-color: #323232; */
  padding: 1.5rem 1rem 1.5rem 1rem;
  margin: 0px;
}

.venere .drink,
.venere .product,
.venere .dish {
  color: black;
}

.venere .powered-by {
  /* background-color: #323232; */
  color: white;
}

.venere .all-black-bg {
  background-color: black;
}

.venere .tab {
  background-color: white;
  color: black;
  opacity: 0.9;
  border: none;
}

.venere .tab-active {
  background-color: grey;
  color: white;
  opacity: 1;
}

.venere .allergens-modal-trigger span {
  background-color: grey;
  color: white;
}

.venere .courses-container > header {
  border-color: black;
}

.venere .arrow-trigger {
  background-color: grey;
}

.venere .product-content {
  overflow: hidden;
}

.venere .bucket {
  color: black;
}
