/* ---VOGLIAARGENTINA--- */
/* Splashpage */
.voglia_argentina .splash {
  color: black;
}

.voglia_argentina .splash button {
  background: #f3c445;
  color: black;
}
/*  */

/* Menupage  fork->list */
.voglia_argentina .tab {
  background: #ffffff;
  border-color: #f3c445;
  color: black;
}

.voglia_argentina .tab-active {
  background: #f3c445;
  border-color: #e7e8e7;
  color: #ffffff;
}

.voglia_argentina .arrow-trigger {
  background: #80aad9;
  box-shadow: none;
}

.voglia_argentina .cta {
  background: #f1e4dd;
}

.voglia_argentina .filter-modal-trigger {
  background: #80aad9;
}

.voglia_argentina .subscription {
  background: #80aad9;
}

.voglia_argentina .share {
  background: #80aad9;
}

.voglia_argentina .drink-content {
  background: #e7e8e7;
  color: black;
}

.voglia_argentina .bucket {
  background: #f1e4dd;
  color: black;
}

.voglia_argentina .cta-tasting-menu {
  background: #886e47;
  color: white;
}

.voglia_argentina .allergens-modal-trigger span {
  background: #f3c445;
  color: white;
}

/*  */
