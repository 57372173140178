.villanichesola .splash {
    color: white;
}

.villanichesola .splash button {
    background: #D64901;
    color: white;
}

.villanichesola #menu-page {
    background-image: url(/public/assets/images/villa_nichesola.png);
}

.villanichesola .menu {
    background-color: rgba(255, 255, 255, 0);
    color: white;
    padding-top: 2rem;
}

.villanichesola .list {
    background-color: rgba(255, 255, 255, 0);
    color: white;
    padding-top: 2rem;
}

.villanichesola .react-tabs__tab-panel,
react-tabs__tab-panel--selected {
    color: black;
}

.villanichesola .tab {
    background: #ffffff;
    border-color: #ffffff;
    color: #D64901;
}

.villanichesola .tab-active {
    background: #D64901;
    border-color: #D64901;
    color: white;
}

.villanichesola .preferred-default-screen {
    background-color: rgba(255, 255, 255, 0);
    color: white;
}

.villanichesola .drinks {
    background-color: rgba(255, 255, 255, 0.5);
}

.villanichesola .drinks.even {
    background-color: rgba(255, 255, 255, 0);
}

.villanichesola .drinks.odd {
    background-color: rgba(0, 0, 0, 0.5);
}

.villanichesola .dishes.even {
    background-color: rgba(255, 255, 255, 0);
}

.villanichesola .dishes.odd {
    background-color: rgba(0, 0, 0, 0.5);
}

.villanichesola .dish>div {
    background-color: rgba(255, 255, 255, 0.8);
}

.villanichesola .trigger.even {
    color: white;
    background-color: rgba(255, 255, 255, 0);
}

.villanichesola .trigger.odd {
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
}

.villanichesola .drink>div {
    background-color: rgba(255, 255, 255, 0.8);
}

.villanichesola .arrow-trigger {
    background: black;
}

.villanichesola .powered-by {
    color: white;
}

.villanichesola .allergens-modal-trigger span {
    background-color: #D64901;
}
