/* ---All Right--- */
/* Splashpage */
.allright * {
    font-family: "League Gothic", sans-serif;
    letter-spacing: 0.05em;
}

.allright .splash button {
    background-color: #FEC701;
    color: black;
}

.allright .splash {
    color: white
}

/*  */

/* Menupage  fork->menu */
.allright .tab {
    background: #3c3c3c;
    border-color: #3c3c3c;
    border-color: #FEC701;
    color: white;
}

.allright .tab-active {
    background: #FEC701;
    border-color: #FEC701;
    color: black;
    background-color: #FEC701;
}

.allright .arrow-trigger {
    background: #E8E0DD;
    color: black;
    box-shadow: none;
}

.allright .cta {
    background: #fec34d;
    color: black;
}

.allright .filter-modal-trigger {
    background: #fec34d;
    color: black;
}

.allright .subscription {
    background: #fec34d;
    color: black;
}

.allright .share {
    background: #fec34d;
    color: black;
}

.allright .add-cart-item {
    background: #fec34d;
    color: black;
}

.allright .allergens-modal-trigger span {
    background: #fec34d;
    color: black;
}

.allright .cta-no-orders {
    background: #fec34d;
    color: black;
}

.allright .menu {
    color: white;
    background: rgba(255, 255, 255, 0);
    margin-top: 0;
}

.allright .even {
    color: white;
    background: rgba(255, 255, 255, 0);
}

.allright .odd {
    color: white;
    background: rgba(255, 255, 255, 0);
}

.allright .powered-by {
    color: white;
}

.allright .dish>div {
    color: black;
    background: white;
    font-family: 'Nunito', sans-serif !important;
}

.allright .drink>div {
    color: black;
    background: white;
    font-family: 'Nunito', sans-serif !important;
}

.allright .ingredients span {
    font-family: 'Nunito', sans-serif;
}

/*  */
