/* ---TEN--- */
/* Splashpage */
.ten .splash {
  color: white;
}

.ten .splash button {
  background: #f70000;
}
/*  */

/* Menupage  fork->list */
.ten .tab {
  background: #383839;
  border-color: #f70000;
  color: white;
}

.ten .share {
  background-color: #f70000;
}

.ten .tab-active {
  background: #f70000;
  border-color: #f70000;
  color: white;
}

.ten .arrow-trigger {
  background: #f70000;
  box-shadow: none;
}

.ten .collapsible-trigger {
  background: #535353;
  color: white;
}

.ten .odd {
  background: #8b8b8b;
  color: white;
}

.ten .dishes {
  background: #8b8b8b;
  color: white;
}

.ten .list {
  background: #f70000;
  color: #e7e8e7;
  font-weight: 900;
}

.ten .product {
  background: #c9cdab;
  color: black;
}

.ten .product-content {
  background: #e7e8e7;
  color: black;
  margin: 25rem;
}

.ten .allergens-modal-trigger span {
  background: #2b2b2b;
}

.ten .powered-by {
  color: #e7e8e7;
  background: #535353;
}

/*  */

/* Menupage  fork->menu */
.ten .menu {
  background: #535353;
  color: white;
}

.ten .dish {
  background: #535353;
  color: black;
}

.ten .odd .dish {
  background: #8b8b8b;
  color: black;
}

.ten .drink{
  color:black;
}

.ten .even .drink {
  background: #535353;
  color: black;
}

.ten .odd .drink {
  color: black;
}

.ten .restaurant-image {
  background: white;
}
/*  */
