/*.gatsby ion-content {
     --background: url('/public/assets/gatsby/bg.png') 0 100px/100% auto repeat;
     filter: brightness(40%);
 }*/

/* .gatsby ion-content {
     --background: #323232;
 } */

.gatsby .splash h1 {
  color: white;
}

.gatsby .splash button {
  background: black;
  color: white;
}

.gatsby .restaurant-image {
  background: white;
}

.gatsby img.restaurant-poster {
  height: 250px;
  object-fit: cover;
}

.gatsby /*.courses-container*/ .list {
  color: white;
  z-index: 1;
  background-color: transparent;
  margin-top: 4px;
}

.gatsby section#menu-page {
  min-height: 100%;
}

.gatsby section#menu-page::before {
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url("/public/assets/images/gatsby.png");
  background-position: 0px 250px;
  filter: brightness(75%);
  background-size: 100% auto;
  box-sizing: border-box;
  z-index: -10;
}

.gatsby .Collapsible .even {
  background: transparent;
}

.gatsby .Collapsible .odd {
  background-color: black;
}

.gatsby .allergens-modal-trigger {
  /* background-color: #323232; */
  padding: 1.5rem 1rem 1.5rem 1rem;
  margin: 0px;
}

.gatsby .drink,
.gatsby .product {
  color: black;
}

.gatsby .powered-by {
  /* background-color: #323232; */
  color: white;
}

.gatsby .all-black-bg {
  background-color: black;
}

.gatsby .tab {
  background-color: white;
  color: black;
  opacity: 0.7;
  border: none;
}

.gatsby .tab-active {
  background-color: black;
  color: white;
  opacity: 1;
}

.gatsby .allergens-modal-trigger span {
  background-color: white;
  color: black;
}

.gatsby .courses-container > header {
  border-color: #323232;
}

.gatsby .arrow-trigger {
  background-color: #0e0a0c;
}

.gatsby .product-content {
  overflow: hidden;
}

.gatsby .bucket {
  color: black;
}
