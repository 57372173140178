/* ---HOTEL DOLOMITI--- */
/* Splashpage */

.hotel-dolomiti .splash button {
  background: #fee9cf;
  color: black;
}
/*  */

/* Menupage  fork->list */

.hotel-dolomiti .tab {
  background: #ffffff;
  border-color: #fee9cf;
  color: black;
}

.hotel-dolomiti .tab-active {
  background: #fee9cf;
  border-color: #fee9cf;
  color: black;
}

.hotel-dolomiti .arrow-trigger {
  background: red;
  color: white;
  box-shadow: none;
}

.hotel-dolomiti .cta {
  background: #fd8900;
}

.hotel-dolomiti .cta > button {
  color: white;
}

.hotel-dolomiti .filter-modal-trigger {
  background: #fee9cf;
  color: black;
}

.hotel-dolomiti .subscription {
  background: #a0480f;
  color: white;
}

.hotel-dolomiti .share {
  background: red;
  color: white;
}

.hotel-dolomiti .bucket {
  background: #fd8900;
  color: black;
}

.hotel-dolomiti .add-cart-item {
  background: #fee9cf;
  color: black;
}

.hotel-dolomiti .allergens-modal-trigger span {
  background: #fee9cf;
  color: black;
}

.hotel-dolomiti .cta-tasting-menu {
  background: #fd8900;
  color: white;
}

.hotel-dolomiti .add-cart-item {
  background: #fd8900;
  color: white;
}

/*  */
