/* ---CAPANNINA--- */
/* Splashpage */
.capannina .splash button {
    background: #774409;
    color: white;
  }
  /*  */

  /* Menupage  fork->list */
  .capannina .tab {
    background: white;
    border-color: #774409;
    color: black;
  }

  .capannina .tab-active {
    background: #774409;
    border-color: #774409;
    color: white;
  }

  .capannina .arrow-trigger {
    background: #774409;
    color: white;
    box-shadow: none;
  }

  .capannina .cta {
    background: #774409;
  }

  .capannina .filter-modal-trigger {
    background: #774409;
    color: white;
  }

  .capannina .allergens-modal-trigger > span{
    background-color:  #774409;
  }

  .capannina .subscription {
    background: #774409;
    color: white;
  }
  
  .capannina .share {
    background: #774409;
    color: white;
  }
  /*  */

  .capannina .drinks {
    background-color: rgba(255, 255, 255, 0.5);
  }
  
  .capannina .drinks.even {
    background-color: rgba(255, 255, 255, 0);
  }
  
  .capannina .drinks.odd {
    background-color: #F1ECE6;
  }
  
  .capannina .dishes.even {
    background-color: rgba(255, 255, 255, 0);
  }
  
  .capannina .dishes.odd {
    background-color: #F1ECE6;
  }
  
  .capannina .dish > div {
    background-color: white;
    border: solid 0.5px #e5e5e5;
  }

  .capannina .drink > div{
      background:white;
      border: solid 0.5px #e5e5e5;
  }
  
  /* .capannina .trigger.even {
    color: white;
    background-color: rgba(255, 255, 255, 0);
  } */
  
  .capannina .trigger.odd {
    background-color: #F1ECE6;
  }
  
  .capannina .arrow-trigger {
    background: #774409;
  }

  .capannina .dish-modal-trigger{
      color:black
  }

  .capannina .drink-modal-trigger{
    color:black
}

.capannina #menu-page > div.menu.relative.-mt-4.py-2.rounded-t-lg.bg-white > header{
    border:none;
}
