/* ---POKE ISLAND--- */
/* Splashpage */

.pokeisland .splash button {
  background: #2c7253;
  color: white;
}
/*  */

/* Menupage  fork->list */
.pokeisland .tab {
  background: #ffffff;
  border-color: #2c7253;
  color: black;
}

.pokeisland .tab-active {
  background: #2c7253;
  border-color: #297d4d;
  color: white;
}

.pokeisland .arrow-trigger {
  background: #2c7253;
  color: white;
  box-shadow: none;
}

.pokeisland .cta {
  background: #fec34d;
}

/* .adr .cta > button {
    color: white;
  } */

.pokeisland .filter-modal-trigger {
  background: #2c7253;
  color: white;
}

.pokeisland .subscription {
  background: white;
  color: #2c7253;
}

.pokeisland .share {
  background: white;
  color: #2c7253;
}

.pokeisland .bucket {
  background: #fec34d;
  color: black;
}

.pokeisland .add-cart-item {
  background: #fec34d;
  color: black;
}

.pokeisland .allergens-modal-trigger span {
  background: #297d4d;
  color: white;
}

.pokeisland .cta-tasting-menu {
  background: #297d4d;
  color: white;
}

.pokeisland .dish-modal-trigger {
  color: #297d4d;
}

.pokeisland .product-content {
  background: white;
}

.pokeisland .collapsible-trigger {
  background-color: #ffbabc;
}

.pokeisland .list {
  background: #eca8aa;
}

.pokeisland .menu {
  background: #eca8aa;
}

.pokeisland .even {
  background: #eca8aa;
}

.pokeisland .odd {
  background-color: #ffbabc;
}
/*  */
