/* ---MOTUEKA--- */
/* Splashpage */
.motueka .splash {
    color: #e7e8e7;
}

.motueka .splash button {
    background: #BD1218;
    color: white;
    font-weight: 900;
}

/*  */

/* Menupage  fork->list */
.motueka .tab {
    border-color: #BD1218;
    color: white;
    border-radius: 0;
    font-weight: 900;
}

.motueka .tab-active {
    background: #BD1218;
    border-color: #BD1218;
    color: white;
    border-radius: 0;
    font-weight: 900;
}

.motueka .arrow-trigger {
    background: #BD1218;
    box-shadow: none;
}

.motueka .cta {
    background: #3f3f4f;
    margin: 1rem;
    border-radius: 0;
}

.motueka .drink-content-name {
    color: black;
    font-weight: 900;
    font-size: larger;
    padding-bottom: 0.75rem;
}

.motueka .drink>div>div>p:nth-child(1) {
    color: white;
    font-weight: 900;
    font-size: larger;
    padding-bottom: 0.75rem;
}

.motueka .cta {
    border: #BD1218;
    background-color: #BD1218;
}

.motueka .cta button {
    color: white;
}

.motueka .collapsible-trigger {
    background: black;
    color: #e7e8e7;
    font-weight: 900;
    font-size: larger;
}

.motueka .product {
    background: #c9cdab;
}

.motueka .product-content {
    background: #e7e8e7;
    color: black;
    margin: 25rem;
}

.motueka .dish {
    padding-left: 5%;
    padding-right: 5%;
    background: black;
    border-radius: 0;
    line-height: 1.2;
    font-size: larger;
    padding-bottom: 0.75rem;
}

.motueka .drink {
    padding-left: 5%;
    padding-right: 5%;
    background: black;
    border-radius: 0;
    line-height: 1.2;
    color: black;
    font-weight: 900;
    font-size: larger;
    padding-bottom: 0.75rem;
}

.motueka .dish p {
    color: white;
    font-weight: 900;
}

.motueka .drink p {
    color: white;
}

.motueka .dish>div {
    border-radius: 0;
    background: #202125;
}

.motueka .dish-modal-trigger {
    color: red;
}

.motueka .dish .allergen-icons {
    background: white;
    padding: 8px;
    padding-left: 8px;
    border-radius: 20px;
    padding-right: 0px;
    margin-right: 1rem;
}

.motueka .drink>div {
    border-radius: 0;
    background: #202125;
}

.motueka .drink-content {
    background: #e7e8e7;
    color: black;
    border-radius: 0;
}

.motueka .button-native {
    background: #eb6440;
}

.motueka .cta-tasting-menu {
    background: #886e47;
    color: white;
}

.motueka .allergens-modal-trigger span {
    background: #BD1218;
    color: white;
    font-weight: 900;
    border-radius: 0;
}

.motueka .powered-by {
    color: #e7e8e7;
    background: black;
}

/*  */

/* Menupage  fork->menu */
.motueka .menu {
    background: black;
    color: #e7e8e7;
    font-weight: 900;
}

.motueka .list {
    background: black;
    color: #e7e8e7;
    font-weight: 900;
}

.motueka .filter-modal-trigger {
    background-color: #BD1218;
}

.motueka .share {
    background-color: white;
    color: black;
}

/*  */
