/* ---ATLANTA--- */
/* Splashpage */
.atlanta .splash {
  color: white;
}

.atlanta .splash button {
  background: #EECB01;
  color: black;
}

/*  */

/* Menupage  fork->menu */
.atlanta .menu {
  color: white;
  background: #1DABE3;
}

.atlanta .tab {
  background: #1DABE3;
  border-color: #EECB01;
  color: white;
}

.atlanta .tab-active {
  background: #EECB01;
  border-color: #EECB01;
  color: black;
}

.atlanta .arrow-trigger {
  background: #362D88;
  box-shadow: none;
}

.atlanta .cta {
  background: #EECB01;
  color: black;
}

.atlanta .filter-modal-trigger {
  background: #EECB01;
  color: black;
}

.atlanta .subscription {
  background: #EECB01;
  color: black;
}

.atlanta .share {
  background: #EECB01;
  color: black;
}

.atlanta .Collapsible:nth-child(odd) div {
  background: #1DABE3;
  color: white;
}

.atlanta .Collapsible:nth-child(even) div {
  background: #158AC4;
  color: white;
}

.atlanta .Collapsible .Collapsible__contentInner div.dish div {
  background-color: white;
  color: black;
}

.atlanta .Collapsible .Collapsible__contentInner div.dish div div {
  border-radius: 10px;
}

.atlanta .Collapsible .Collapsible__contentInner div.drink div {
  background-color: white;
  color: black;
  border-radius: 10px;
}

.atlanta .bucket {
  background: #EECB01;
  color: black;
}

.atlanta .cta-tasting-menu {
  background: #EECB01;
  color: black;
}

.atlanta .allergens-modal-trigger span {
  background: #EECB01;
  color: black;
}

.atlanta .powered-by {
  color: white;
}

.atlanta .drink {
  color: black;
}

.atlanta .dish-modal-trigger {
  color: #03012C;
}

/*  */
