/* ---MONTADITOS--- */
/* Splashpage */

.montaditos .splash button {
  background: #844a2e;
  color: white;
  border-radius: 9999px;
}
/*  */

/* Menupage  fork->list */
.montaditos .product-content {
  background: #f5efe4;
}

.montaditos .tab {
  background: #ffffff;
  border-color: #cb454a;
  color: black;
}

.montaditos .tab-active {
  background: #cb454a;
  border-color: #cb454a;
  color: white;
}

.montaditos .arrow-trigger {
  background: #cb454a;
  color: white;
  box-shadow: none;
}

.montaditos .odd {
  background: #f5efe4;
}

.montaditos .odd .product-content {
  background: #ffffff;
  color: black;
}

.montaditos .even .drink-content {
  background: #f5efe4;
  color: black;
}

.montaditos .odd .drink {
  color: black;
}

.montaditos .drink-content {
  background-color: white;
}

.montaditos .delivery-popup-modal-content > :nth-child(2) {
  background: #cb454a;
  color: white;
}

.montaditos .drink-modal-trigger {
  color: #3b220d;
}

.montaditos .cta {
  background: #844a2e;
  color: white;
}

.montaditos .cta > button {
  background: #844a2e;
  color: white;
}

.montaditos .filter-modal-trigger {
  background: #cb454a;
  color: white;
}

.montaditos .subscription {
  background: #429b21;
  color: white;
}

.montaditos .share {
  background: #429b21;
  color: white;
}

.montaditos .bucket {
  background: #844a2e;
  color: white;
}

.montaditos .bucket > div {
  fill: #fff;
}

.montaditos .add-cart-item {
  background: #844a2e;
  color: white;
}

.montaditos .allergens-modal-trigger span {
  background: #cb454a;
  color: white;
}

.montaditos .cta-tasting-menu {
  background: #844a2e;
  color: white;
}

.montaditos .details span {
  color: #3b220d;
}
/*  */
