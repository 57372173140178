/* ---REDCAFE--- */
/* Splashpage */
.redcafe .splash {
  color: #e30613;
}

.redcafe .splash button {
  background: #e30613;
}
/*  */

/* Menupage  fork->list */
.redcafe .tab {
  background: #c9cdab;
  border-color: #c9cdab;
  color: white;
}

.redcafe .tab-active {
  background: #d6c2d2;
  border-color: #d6c2d2;
}

.redcafe .arrow-trigger {
  background: #eb6440;
}

.redcafe .cta {
  background: #d6c2d2;
}

.redcafe .cta button {
  color: white;
}

.redcafe .collapsible-trigger {
  background: #eee4d2;
}

.redcafe .list {
  background: #eee4d2;
  color: #e30613;
}

.redcafe .product {
  background: #c9cdab;
}

.redcafe .product-content {
  background: #eee4d2;
  color: black;
}

.redcafe .drink {
  background: #c9cdab;
}

.redcafe .drink-content {
  background: #eee4d2;
  color: black;
}

.redcafe .button-native {
  background: #e30613;
}

.redcafe .cta-tasting-menu {
  background: #886e47;
  color: white;
}

.redcafe .allergens-modal-trigger span {
  background: #e30613;
}

.redcafe .powered-by {
  color: #e30613;
}

/*  */

/* Menupage  fork->menu */

/*  */
